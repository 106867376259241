import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'salesRegister',
  components: {
    DatePicker
  },
  watch: {
    selectedLegalEntity: function() {
      this.parent_value_set_id = this.selectedLegalEntity.value;
    },
    currentPage: function() {
      this.getSalesRegister();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getSalesRegister();
    }
  },
  validations: {
    selectedLegalEntity: {
      value: {
        required
      }
    }
    // startDate: {
    //   required
    // },
    // endDate: {
    //   required
    // }
  },
  props: ['searchFilters'],
  data() {
    return {
      editMode: false,
      loading: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      currentPage: 1,
      defaultValue: {
        value: null,
        text: null
      },
      selectedLegalEntity: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      selectedGSTNumber: {
        value: null,
        text: null
      },
      source: {
        value: null,
        text: null
      },
      trxCategory: {
        value: null,
        text: null
      },
      isHold: {
        value: null,
        text: null
      },
      periodName: {
        value: null,
        text: null
      },
      payload: null,
      periodType: null,
      startDate: null,
      endDate: null,
      invoiceNumber: null,
      correctInvoiceNumber: null,
      thirdPartyGst: null,
      reco_flag: {
        value: null,
        text: null
      },
      correction_flag: {
        value: null,
        text: null
      },
      docType: {
        value: null,
        text: null
      },
      show_all_flag: true,
      parent_value_set_id: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      showValueSetModal: false,
      selectAllCheckBox: false,
      selectedRow: [],
      salesRegisterData: [],
      salesRegisterFields: [
        {
          key: 'period_name',
          class: 'text-center'
        },
        {
          key: 'operating_unit',
          class: 'text-center'
        },
        {
          key: 'trx_category',
          label: 'Transaction Category',
          class: 'text-center'
        },
        // {
        //   key: 'trx_desc',
        //   label: 'Transaction Description',
        //   class: 'col-fix text-center'
        // },
        {
          key: 'source',
          class: 'text-center'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type',
          class: 'text-center'
        },
        {
          key: 'doc_type',
          class: 'text-center'
        },
        {
          key: 'invoice_num',
          class: 'text-center'
        },
        {
          key: 'invoice_date',
          class: 'text-center'
        },
        {
          key: 'invoice_curr_code',
          label: 'Invoice Current Code',
          class: 'text-center'
        },
        // {
        //   key: 'vendor_po_ref_num',
        //   label: 'Vendor PO Ref Num',
        //   class: 'col-fix text-center'
        // },
        {
          key: 'supp_legal_name',
          label: 'Supplier Legal Name',
          class: 'text-center'
        },
        {
          key: 'supp_gstn',
          label: 'Supplier GSTN',
          class: 'text-center'
        },
        {
          key: 'bill_to_name',
          class: 'text-center'
        },
        {
          key: 'bill_to_gstn',
          label: 'Bill to GSTN',
          class: 'text-center'
        },
        {
          key: 'bill_to_country',
          class: 'text-center'
        },
        {
          key: 'ship_to_name',
          class: 'text-center'
        },
        {
          key: 'ship_to_gstn',
          label: 'Ship to GSTN',
          class: 'text-center'
        },
        {
          key: 'exchange_rate',
          class: 'text-center'
        },
        {
          key: 'total_inv_amt',
          class: 'text-center'
        },
        {
          key: 'inv_amt_fun_curr',
          class: 'text-center'
        },
        {
          key: 'item_code',
          class: 'text-center'
        },
        {
          key: 'item_desc',
          class: 'text-center'
        },
        {
          key: 'hsn_code',
          label: 'HSN Code',
          class: 'text-center'
        },
        {
          key: 'uqc',
          label: 'UQC',
          class: 'text-center'
        },
        {
          key: 'qty',
          class: 'text-center'
        },
        {
          key: 'unit_selling_price',
          class: 'text-center'
        },
        {
          key: 'pos',
          label: 'POS',
          class: 'text-center'
        },
        {
          key: 'line_amt',
          label: 'Taxable Amount',
          class: 'text-center'
        },
        {
          key: 'discount_amt',
          class: 'text-center'
        },
        {
          key: 'total_rate',
          class: 'text-center'
        },
        {
          key: 'igst_rate',
          label: 'IGST Rate',
          class: 'text-center'
        },
        {
          key: 'igst_amt',
          label: 'IGST Amount',
          class: 'text-center'
        },
        {
          key: 'cgst_rate',
          label: 'CGST Rate',
          class: 'text-center'
        },
        {
          key: 'cgst_amt',
          label: 'CGST Amount',
          class: 'text-center'
        },
        {
          key: 'sgst_rate',
          label: 'SGST Rate',
          class: 'text-center'
        },
        {
          key: 'sgst_amt',
          label: 'SGST Amount',
          class: 'text-center'
        },
        {
          key: 'cess_rate',
          class: 'text-center'
        },
        {
          key: 'cess_amt',
          class: 'text-center'
        },
        {
          key: 'total_tax',
          class: 'text-center'
        },
        {
          key: 'shipping_bill_port_code',
          label: 'Shipping Bill Port Code',
          class: 'text-center'
        },
        {
          key: 'shipping_bill_num',
          label: 'Shipping Bill Num',
          class: 'text-center'
        },
        {
          key: 'shipping_bill_date',
          label: 'Shipping Bill Date',
          class: 'text-center'
        },
        {
          key: 'correction_flag',
          class: 'text-center'
        },
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'correct_invoice_number',
          label: 'Correct Invoice Number',
          class: 'text-center'
        },
        {
          key: 'correct_invoice_date',
          class: 'text-center'
        },
        {
          key: 'is_hold',
          class: 'text-center'
        },
        {
          key: 'correct_fp_gstn',
          label: 'Correct FP GSTN',
          class: 'text-center'
        },
        {
          key: 'correct_tp_gstn',
          label: 'Correct TP GSTN',
          class: 'text-center'
        },
        {
          key: 'correct_pos',
          label: 'Correct POS',
          class: 'text-center'
        },
        {
          key: 'correct_trx_category',
          class: 'text-center'
        },
        {
          key: 'correct_period_name',
          class: 'text-center'
        },
        {
          key: 'correct_hsn',
          label: 'Correct HSN',
          class: 'text-center'
        },
        {
          key: 'correct_uqc',
          label: 'Correct UQC',
          class: 'text-center'
        },
        {
          key: 'correction_made_date',
          class: 'text-center'
        },
        {
          key: 'correct_shipping_bill_port_code',
          label: 'Correct Shipping Bill Port Code',
          class: 'text-center'
        },
        {
          key: 'correct_shipping_bill_num',
          label: 'Correct Shipping Bill Num',
          class: 'text-center'
        },
        {
          key: 'correct_shipping_bill_date',
          label: 'Correct Shipping Bill Date',
          class: 'text-center'
        },
        {
          key: 'correction_remarks',
          class: 'text-center'
        }
      ],
      specIndex: null,
      unsubscribe: null,
      colorFlag: false,
      showExcelUploadModal: false,
      totalInvAmt: 0,
      totalTaxableAmt: 0,
      totalTax: 0
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loading = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'itcDataUpdation/salesRegister',
            'sales-register',
            () => (this.loading = false)
          );
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditSalesRegisterData();
          this.editMode = false;
          this.selectAllCheckBox = false;
        }
        if (actionName === 'update' || actionName === 'edit') {
          this.editMode = !this.editMode;
        }
        if (actionName === 'upload') {
          this.showExcelUploadModal = true;
        }
      }
    });

    if (this.searchFilters) {
      if (this.$route.name === 'gst_r1_summary') {
        this.colorFlag = true;
      }
      this.getParentToChildData(this.searchFilters);
    }
  },
  methods: {
    // showFlag(value) {
    //   if (value === 'recoFlag' || value === 'correctionFlag') {
    //     this.show_all_flag = '0';
    //   }
    //   if (value === 'showAll') {
    //     this.reco_flag = '0';
    //     this.correction_flag = '0';
    //   }
    // },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.salesRegisterData = this.salesRegisterData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.salesRegisterData;
      } else {
        this.salesRegisterData = this.salesRegisterData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
      }
    },
    selectBoxChecked(flag, index, item, invoiceNum) {
      this.salesRegisterData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.invoice_num !== invoiceNum
        );
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      // this.periodType = periodType;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.GST_OPERATING_UNIT) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.ouName = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GST_OPERATING_UNIT) {
        this.ouName = {
          value: item.value_code,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        // this.selectedGSTNumber = {
        //   value: item.value_set_dtl_id,
        //   text: item.value_code
        // };
        if (this.specIndex === null) {
          this.selectedGSTNumber = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else {
          this.salesRegisterData[this.specIndex].correct_fp_gstn =
            item.value_code;
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE) {
        this.source = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GSTR1_INVOICE_CATEGORIES
      ) {
        if (this.specIndex === null) {
          this.trxCategory = {
            value: item.value_code,
            text: item.value_meaning
          };
        } else {
          this.salesRegisterData[this.specIndex].correct_trx_category =
            item.value_code;
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        if (this.specIndex === null) {
          this.isHold = {
            value: item.value_code,
            text: item.value_meaning
          };
        } else {
          this.salesRegisterData[this.specIndex].is_hold = item.value_meaning;
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.specIndex === null) {
          this.periodName = {
            value: item.value_code,
            text: item.period_name
          };
        } else {
          this.salesRegisterData[this.specIndex].correct_period_name =
            item.value_set_dtl_id;
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ZERO_ONE) {
        if (this.specIndex === 'reco_flag') {
          this.reco_flag = {
            value: item.value_code,
            text: item.value_meaning
          };
        } else {
          this.correction_flag = {
            value: item.value_code,
            text: item.value_meaning
          };
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GST_DOCUMENT_TYPE) {
        this.docType = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      this.salesRegisterData = [];
      this.selectedLegalEntity = this.defaultValue;
      this.ouName = this.defaultValue;
      this.selectedGSTNumber = this.defaultValue;
      this.source = this.defaultValue;
      this.invoiceNumber = null;
      this.correctInvoiceNumber = null;
      this.startDate = null;
      this.endDate = null;
      this.reco_flag = this.defaultValue;
      this.correction_flag = this.defaultValue;
      this.thirdPartyGst = null;
      this.trxCategory = this.defaultValue;
      this.isHold = this.defaultValue;
      this.reco_flag = this.defaultValue;
      this.correction_flag = this.defaultValue;
      this.docType = this.defaultValue;
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === this.selectedLegalEntity.value) {
        this.selectedLegalEntity = this.defaultValue;
      } else if (vsetCode === this.ouName.value) {
        this.ouName = this.defaultValue;
      } else if (vsetCode === this.selectedGSTNumber.value) {
        this.selectedGSTNumber = this.defaultValue;
      } else if (vsetCode === this.source.value) {
        this.source = this.defaultValue;
      } else if (vsetCode === this.trxCategory.value) {
        this.trxCategory = this.defaultValue;
      } else if (vsetCode === this.isHold.value) {
        this.isHold = this.defaultValue;
      } else if (vsetCode === 'fpGstnParty') {
        this.salesRegisterData[index].correct_fp_gstn = null;
      } else if (vsetCode === 'trxCat') {
        this.salesRegisterData[index].correct_trx_category = null;
      } else if (vsetCode === 'holdFlag') {
        this.salesRegisterData[index].is_hold = null;
      } else if (vsetCode === 'periodName') {
        this.salesRegisterData[index].correct_period_name = null;
      } else if (vsetCode === this.periodName.value) {
        this.periodName = this.defaultValue;
      } else if (vsetCode === this.reco_flag.value) {
        this.reco_flag = this.defaultValue;
      } else if (vsetCode === this.correction_flag.value) {
        this.correction_flag = this.defaultValue;
      } else if (vsetCode === this.docType.value) {
        this.docType = this.defaultValue;
      }
    },
    getSalesRegister() {
      this.totalInvAmt = 0;
      this.totalTaxableAmt = 0;
      this.totalTax = 0;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.selectedLegalEntity.value,
          operating_unit: this.ouName.value,
          gstn_no: this.selectedGSTNumber.value,
          trx_category: this.trxCategory.value,
          IS_HOLD: this.isHold.value,
          source: this.source.value,
          prdfrom: this.startDate,
          prdto: this.endDate,
          invoice_num: this.invoiceNumber,
          correct_invoice_number: this.correctInvoiceNumber,
          reco_flag: this.reco_flag.value,
          correction_flag: this.correction_flag.value,
          bill_to_gstn: this.thirdPartyGst,
          period_name: this.periodName.value,
          doc_type: this.docType.value
        };
        this.loading = true;
        this.$store
          .dispatch('itcDataUpdation/salesRegister', this.payload)
          .then(resp => {
            this.loading = false;
            if (resp.status === 200) {
              const result = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
              this.salesRegisterData = result;
              this.salesRegisterData.forEach(ele => {
                ele.correct_invoice_date = commonHelper.convertDateFormat(
                  ele.correct_invoice_date
                );
                ele.correction_made_date = commonHelper.convertDateFormat(
                  ele.correction_made_date
                );
                ele.is_hold = ele.is_hold == 'Y' ? 'Yes' : 'No';
              });
              for (let i of this.salesRegisterData) {
                this.totalInvAmt = this.totalInvAmt + i.total_inv_amt;
                this.totalTaxableAmt = this.totalTaxableAmt + i.line_amt;
                this.totalTax = this.totalTax + i.total_tax;
              }
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    addEditSalesRegisterData() {
      const filterData = this.salesRegisterData.filter(data => data.selectBox);
      const itemDetails = filterData.map(elem => {
        return {
          dtl_id: elem.dtl_id,
          correct_invoice_date: elem.correct_invoice_date
            ? commonHelper.convertDateFormat(elem.correct_invoice_date)
            : elem.invoice_date,
          correct_invoice_number: elem.correct_invoice_number
            ? elem.correct_invoice_number
            : elem.invoice_num,
          correct_third_party_gst: elem.correct_tp_gstn,
          correct_first_party_gst: elem.correct_fp_gstn,
          correct_place_of_supplier: elem.correct_pos
            ? elem.correct_pos
            : elem.pos,
          correct_trx_category: elem.correct_trx_category
            ? elem.correct_trx_category
            : elem.trx_category,
          correct_period_name: elem.correct_period_name
            ? elem.correct_period_name
            : elem.period_name,
          correct_hsn: elem.correct_hsn ? elem.correct_hsn : elem.hsn_code,
          correct_uqc: elem.correct_uqc ? elem.correct_uqc : elem.uqc,
          correct_hdr_id: elem.correct_hdr_id,
          correct_shipping_bill_port_code: elem.correct_shipping_bill_port_code
            ? elem.correct_shipping_bill_port_code
            : elem.shipping_bill_port_code,
          correct_shipping_bill_num: elem.correct_shipping_bill_num
            ? elem.correct_shipping_bill_num
            : elem.shipping_bill_num,
          correct_shipping_bill_date: elem.correct_shipping_bill_date
            ? elem.correct_shipping_bill_date
            : elem.shipping_bill_date,
          is_hold: elem.is_hold === 'Yes' ? 'Y' : 'N',
          correction_remarks: elem.correction_remarks
        };
      });
      const payload = {
        data: itemDetails
      };
      this.loader = true;
      this.$store
        .dispatch('itcDataUpdation/addEditSalesRegister', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.salesRegisterData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
            this.getSalesRegister();
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    getParentToChildData(item) {
      this.selectedLegalEntity = item.legalEntity;
      this.periodName = {
        value: item.month.text,
        text: item.month.text
      };
      this.selectedGSTNumber = item.gstNumber;
      this.trxCategory = {
        value: item.category,
        text: item.category
      };
      this.getSalesRegister();
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
